<template>
	<div class="adduser_box">
		<el-row :gutter="12">
			<el-col :span="8">
				<el-card
					shadow="always"
					class="flex aligin_items_c justify_content_c pointer"
				>
					<div
						style="width: 100%; height: 100%"
						@click="$router.push({ path: '/addpersonal' })"
					>
						普通用户</div
					>
				</el-card>
			</el-col>
			<el-col :span="8">
				<el-card
					shadow="always"
					class="flex aligin_items_c justify_content_c pointer"
				>
					<div
						style="width: 100%; height: 100%"
						@click="$router.push({ path: '/addfirm' })"
					>
						企业用户</div
					>
				</el-card>
			</el-col>
		</el-row>
	</div>
</template>

<script>
export default {
	name: "",
	data() {
		return {};
	},
	methods: {
		toPath() {
			alert("dddds");
		},
	},
};
</script>

<style scoped>
.adduser_box {
	width: 50%;
	margin: 0 auto;
	margin-top: 20%;
}
</style>
<style>
.adduser_box .el-card__body {
	padding: 0;
	width: 100%;
	height: 40px;
}
.adduser_box .el-card__body div {
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
